import { useParams } from "react-router-dom";
import { Card } from "./Card";
import { OfferLoader } from "./Offer";
import { PageLayout } from "./PageLayout";

interface OffertPageProps {}

export function OfferPage(props: OffertPageProps) {
  const { tenantId, projectId, offerId } = useParams();
  return (
    <PageLayout>
      <Card>
        <OfferLoader
          tenantId={tenantId || ""}
          projectId={projectId || ""}
          offerId={offerId || ""}
        />        
      </Card>
    </PageLayout>
  );
}
