import { PropsWithChildren } from "react";
import './Card.css';

export function Card(props: PropsWithChildren) {
  return (
    <div className='Card'>
      {props.children}
    </div>
  );
}
