import { PropsWithChildren } from "react";
import KaisaLogo from "../assets/kneg-black.svg";
import "./PageLayout.css";

export function PageLayout(props: PropsWithChildren) {
  return (
    <div className="PageLayout">
      <img src={KaisaLogo} alt="Logo" className="PageLayout-logo" />

      {props.children}
    </div>
  );
}
