import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import './OfferDialog.css';

export interface ConfirmationDialogProps {
  id: string;
  keepMounted: boolean;
  open: boolean;
  offerName: string;
  onClose: (isAccepted: boolean) => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { onClose, open, offerName, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleAccept = () => {
    onClose(true);
  };

  return (
    <Dialog
      className='offer-dialog accept-dialog'
      sx={{ '& .MuiDialog-paper': { width: '80%', borderRadius: 5 } }}
      maxWidth='xs'
      open={open}
      {...other}
    >
      <DialogTitle className='title'>Godkänn offert?</DialogTitle>
      <DialogContent className='content'>
        Är du säker på att du vill godkänna offerten '<span className='highlighted-text'>{offerName}</span>'? {'\n\n'}
        <br />
        <br />
        Detta svar är bindande.
      </DialogContent>
      <DialogActions className='action-buttons'>
        <Button className='decline-button' autoFocus onClick={handleCancel}>
          Avbryt
        </Button>
        <Button className='confirm-button' onClick={handleAccept}>
          Godkänn offert
        </Button>
      </DialogActions>
    </Dialog>
  );
}
