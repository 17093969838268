import { PropsWithChildren } from 'react';
import CheckboxFilledIcon from '../assets/icons/checkbox-filled.svg';
import ErrorIcon from '../assets/icons/error.svg';

export function SuccessMessage(props: PropsWithChildren) {
  return (
    <div className='success-message'>
      <img src={CheckboxFilledIcon} alt='check' />
      {props.children}
    </div>
  );
}

export function ErrorMessage(props: PropsWithChildren) {
  return (
    <div className='error-message'>
      <img src={ErrorIcon} alt='error' />
      {props.children}
    </div>
  );
}

export function ReasonMessage(props: PropsWithChildren) {
  return (
    <div className='decline-reason-message'>
      <div className='section-header'>Anledning</div>
      <div className='section'>{props.children}</div>
    </div>
  );
}
