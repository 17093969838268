import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ErrorMessage, ReasonMessage, SuccessMessage } from "./messages";
import "./Offer.css";
import CheckIcon from "../assets/icons/check-black.svg";
import ErrorIcon from "../assets/icons/error-black.svg";
import LetterSuccessImage from "../assets/letter-success.svg";
import LetterDeclineImage from "../assets/letter-decline.svg";
import { OfferData, ProjectData, ProjectInfoItem, ProjectTask } from "../data-access/data-access";
import ConfirmationDialog from "./AcceptOfferDialog";
import DeclineDialog from "./DeclineOfferDialog";
import { functions } from "../firestore";
import { httpsCallable } from "firebase/functions";
import { LinearProgress } from "@mui/material";
import { tasks } from "firebase-functions/v1";

interface CustomerDetailsProps {
  customerName: string;
  address: string;
  contactName: string;
  phone: string;
  email: string;
}

export function addSpaceAfter3(price: number) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function CustomerDetails(props: CustomerDetailsProps) {
  return <div></div>;
}

interface SaveSuccessProps {
  projectName: string;
  reasonGiven?: boolean;
}
function SaveSuccess(props: SaveSuccessProps) {
  return (
    <div className="Offer">
      <h1>Offert godkänd</h1>
      <div className="letter-success">
        <img src={LetterSuccessImage} alt="Success" className="letter-success" />
      </div>
      <h2>{props.projectName}</h2>
      <p>
        Vi tackar för ert förtroende att utföra rubricerat uppdrag. Vi kommer nu att skicka ut en
        servicetekniker så fort som möjligt för att ta hand om ärendet. Vi meddelar er när uppdraget
        är slutfört eller vid möjliga komplikationer.
      </p>

      <p>
        Vid funderingar ring eller maila oss på:
        <br />
        <strong>+46 873 23 32</strong>
        <br />
        <strong>kontakt@kaisa.se</strong>
      </p>
    </div>
  );
}

function DeclineSuccess(props: SaveSuccessProps) {
  return (
    <div className="Offer">
      <h1>Offert nekad</h1>
      <div className="letter-decline">
        <img src={LetterDeclineImage} alt="Decline" className="letter-decline" />
      </div>
      <h2>{props.projectName}</h2>
      <p>Tack för ditt svar!</p>
      <p>
        Vid funderingar ring eller maila oss på:
        <br />
        <strong>+46 873 23 32</strong>
        <br />
        <strong>kontakt@kaisa.se</strong>
      </p>
    </div>
  );
}

interface OfferDates {
  createdAt?: any;
  declinedAt?: any;
  acceptedAt?: any;
}
// TODO: something wierd happends with type Timestamp.
// It returns Timestamp-like object that does not contain .toDate() method as it should, but instead _seconds and _nanoseconds props

function OfferDatesBlock(props: OfferDates) {
  return (
    <div className="offer-dates">
      {props.createdAt && (
        <div className="date-section">
          <div className="section-header">Offert skapad:</div>
          <div className="section-small">
            {new Date(props.createdAt._seconds * 1000).toLocaleDateString("se-SV")}
          </div>
        </div>
      )}
      {props.acceptedAt && (
        <div className="date-section">
          <div className="section-header">Offert godkänd:</div>
          <div className="section-small">
            {new Date(props.acceptedAt._seconds * 1000).toLocaleDateString("se-SV")}
          </div>
        </div>
      )}
      {props.declinedAt && (
        <div className="date-section">
          <div className="section-header">Offert makulerad:</div>
          <div className="section-small">
            {new Date(props.declinedAt._seconds * 1000).toLocaleDateString("se-SV")}
          </div>
        </div>
      )}
    </div>
  );
}

interface OffertProps {
  tenantId: string;
  projectId: string;
  offerId: string;
}

export function OfferLoader(props: OffertProps) {
  const { tenantId, projectId, offerId } = props;
  const [offerData, setOfferData] = useState<OfferData | null>();
  const [projectData, setProjectData] = useState<ProjectData | null>();
  const [projectTasks, setProjectTasks] = useState<ProjectTask[]>([]);
  const [projectInfoItems, setProjectInfoItems] = useState<ProjectInfoItem[]>([]);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [displaySaveSuccess, setDisplaySaveSuccess] = useState<boolean>(false);
  const [displayDeclineSuccess, setDisplayDeclineSuccess] = useState<boolean>(false);
  const [openAcceptDialog, setOpenAcceptDialog] = useState<boolean>(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  // Firebase functions calls
  const getOffer = httpsCallable(functions, "getOffer");
  const updateOffer = httpsCallable(functions, "updateOffer");
  const getProject = httpsCallable(functions, "getProject");
  const updateProject = httpsCallable(functions, "updateProject");
  const getProjectTasks = httpsCallable(functions, "getProjectTasks");
  const getProjectInfoItems = httpsCallable(functions, "getProjectInfoItems");

  useEffect(() => {
    const fetchOfferData = async () => {
      const offer = await getOffer({ tenantId, projectId, offerId });
      if (offer.data === null) {
        setErrorMessage("No such offer");
      } else {
        console.log("Setting offer data", offer);
        setOfferData(offer.data as OfferData);
      }
    };
    const fetchProjectData = async () => {
      const project = await getProject({ tenantId, projectId });
      if (project === null) {
        setErrorMessage("No such project");
      } else {
        setProjectData(project.data as ProjectData);
        setLoadingTasks(true);
        const tasks = await getProjectTasks({ tenantId, projectId });
        setLoadingTasks(false);
        setLoadingInfo(true);
        setProjectTasks(tasks.data as ProjectTask[]);
        const infoItems = await getProjectInfoItems({ tenantId, projectId });
        setLoadingInfo(false);
        setProjectInfoItems(infoItems.data as ProjectInfoItem[]);
        console.log("Setting project data", project);
        console.log("Setting task data", tasks);
        console.log("Setting info data", infoItems);
      }
    };
    if (offerData === undefined) {
      setOfferData(null); // Loading
      fetchOfferData().catch(console.error);
    }
    if (projectData === undefined) {
      setProjectData(null); // Loading
      fetchProjectData().catch(console.error);
    }
  });

  const handleCloseAcceptDialog = (isAccepted: boolean) => {
    setOpenAcceptDialog(false);

    if (isAccepted) {
      handleClickAccept();
    } else {
      setOpenAcceptDialog(false);
    }
  };

  const handleCloseDeclineDialog = (isDeclined: boolean, reason?: String) => {
    setOpenDeclineDialog(false);

    if (isDeclined) {
      handleClickDecline(reason);
    } else {
      setOpenDeclineDialog(false);
    }
  };

  const handleClickAccept = async () => {
    console.log("Accepting...");
    setFormLoading(true);
    try {
      await updateOffer({
        tenantId,
        projectId,
        offerId,
        offerData: {
          status: "accepted",
          acceptedAt: Timestamp.now(),
        },
      });
      setDisplaySaveSuccess(true);
      setFormLoading(false);
      console.log("Saved");
    } catch (err) {
      setErrorMessage("Ett fel inträffade!");
      setFormLoading(false);
      console.log("Error!!");
    }
  };

  const handleClickDecline = async (reason?: String) => {
    console.log("Declining...");
    setFormLoading(true);
    try {
      await updateOffer({
        tenantId,
        projectId,
        offerId,
        offerData: {
          status: "cancelled",
          declineReason: reason,
          declinedAt: Timestamp.now(),
        },
      });
      setDisplayDeclineSuccess(true);
      setFormLoading(false);
      console.log("Saved");
    } catch (err) {
      setErrorMessage("Ett fel inträffade!");
      setFormLoading(false);
      console.log("Error!!");
    }
  };

  const getProgressValue = () => {
    if (!offerData) return 0;
    if (!projectData) return 33;
    if (loadingTasks) return 66;
    if (loadingInfo) return 90;
  };

  const getProgressReadable = () => {
    if (!offerData) return "Hämtar offert...";
    if (!projectData) return "Hämtar info om projektet...";
    if (loadingTasks) return "Hämtar uppgifter...";
    return "Strax klart...";
  };

  if (errorMessage) return <div>{errorMessage}</div>;
  if (offerData === undefined || projectData === undefined) return <div>Laddar...</div>;
  if (offerData === null || projectData === null || loadingInfo || loadingTasks)
    return (
      <div>
        <h1>Laddar Offert ...</h1>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={getProgressValue()}
        ></LinearProgress>
        <div style={{ marginTop: 10, marginBottom: 10 }}>{getProgressReadable()}</div>
        <div className="skeleton status-blob"></div>
        <div className="skeleton header-blob"></div>
        <div className="skeleton tagline-blob"></div>
        <div className="skeleton"></div>
        <div className="skeleton short"></div>
        <div className="skeleton tagline-blob"></div>
        <div className="skeleton task-blob"></div>
        <div className="skeleton tagline-blob"></div>
        <div className="skeleton task-blob"></div>
        <div className="skeleton task-blob"></div>
      </div>
    );

  if (displaySaveSuccess) {
    return <SaveSuccess projectName={projectData.name} />;
  }

  if (displayDeclineSuccess) {
    return <DeclineSuccess projectName={projectData.name} />;
  }

  return (
    <div className="Offer">
      <h1>Offert</h1>

      {offerData.status === "accepted" && <SuccessMessage>Offert godkänd</SuccessMessage>}
      {offerData.status === "cancelled" && <ErrorMessage>Offerten makulerad</ErrorMessage>}
      {offerData.status === "cancelled" && offerData.declineReason && (
        <ReasonMessage>{offerData.declineReason}</ReasonMessage>
      )}
      {offerData.status === "cancelled" && !offerData.declineReason && (
        <ReasonMessage>Ingen anledning angiven</ReasonMessage>
      )}

      <h2>{projectData.name}</h2>
      <div className="section-header">Uppdragsbeskrivning</div>
      <div className="section">{offerData.projectDescription}</div>

      {projectInfoItems.length > 0 &&
        projectInfoItems.find((pii) => pii.includedInOffer === true) && (
          <>
            <div className="section-header">Extra information</div>
            <div className="section">
              <ul className="info-list">
                {projectInfoItems.map((infoItem) => {
                  //allows to display undefined for backward capability
                  if (infoItem.includedInOffer == undefined || infoItem.includedInOffer) {
                    return <li key={infoItem.title}>{infoItem.title}</li>;
                  }
                })}
              </ul>
            </div>
          </>
        )}
      {projectTasks.length > 0 && projectTasks.find((pt) => pt.includedInOffer === true) && (
        <>
          <div className="section-header">Arbetsuppgifter</div>
          <div className="section">
            <ul className="task-list">
              {projectTasks.map((task) => {
                //allows to display undefined for backward capability
                if (task.includedInOffer == undefined || task.includedInOffer) {
                  return (
                    <li key={task.title}>
                      {task.title}
                      <ul>
                        {task.steps &&
                          task.steps.map((step) => {
                            //allows to display undefined for backward capability
                            if (step.includedInOffer == undefined || step.includedInOffer) {
                              return (
                                <li style={{ marginLeft: 20 }} key={step.title}>
                                  {step.title}
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </>
      )}

      <div className="section-header">Pris ex. moms</div>
      <h1>{addSpaceAfter3(offerData.price)} SEK</h1>

      <OfferDatesBlock
        createdAt={offerData.createdAt}
        declinedAt={offerData.declinedAt}
        acceptedAt={offerData.acceptedAt}
      ></OfferDatesBlock>

      {formLoading && (
        <p>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          Vänligen vänta medans offerten behandlas.
        </p>
      )}
      <div className="action-buttons">
        {offerData.status === "pending" && (
          <div>
            <button
              onClick={() => setOpenAcceptDialog(true)}
              className={formLoading ? "accept-button loading" : "accept-button"}
            >
              <img src={CheckIcon} alt="Check" />
              Godkänn offert
            </button>
          </div>
        )}
        {offerData.status === "pending" && (
          <div>
            <button
              onClick={() => setOpenDeclineDialog(true)}
              className={formLoading ? "decline-button loading" : "decline-button"}
            >
              <img src={ErrorIcon} alt="Error" /> Neka offert
            </button>
          </div>
        )}
      </div>
      <ConfirmationDialog
        id="accept-dialog"
        keepMounted
        offerName={projectData.name}
        open={openAcceptDialog}
        onClose={handleCloseAcceptDialog}
      />
      <DeclineDialog
        id="decline-dialog"
        keepMounted
        offerName={projectData.name}
        open={openDeclineDialog}
        onClose={handleCloseDeclineDialog}
      ></DeclineDialog>
    </div>
  );
}
