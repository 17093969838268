import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB1nEJhiD7Je4on4wOI_uQyFrSZVk5MPL0",
  authDomain: "kaisa-tool.firebaseapp.com",
  projectId: "kaisa-tool",
  storageBucket: "kaisa-tool.appspot.com",
  messagingSenderId: "28575632191",
  appId: "1:28575632191:web:841b103625a49df29b218a",
};

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);
export const db = getFirestore(app);
