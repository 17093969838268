import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { TextField } from "@mui/material";
import "./OfferDialog.css";

export interface DeclineDialogProps {
  id: string;
  keepMounted: boolean;
  open: boolean;
  offerName: string;
  onClose: (isAccepted: boolean, reason?: String) => void;
}

export default function DeclineDialog(props: DeclineDialogProps) {
  const { onClose, open, offerName, ...other } = props;
  const [reason, setReason] = React.useState<String | undefined>(undefined);

  const handleCancel = () => {
    onClose(false);
  };

  const handleDecline = () => {
    onClose(true, reason);
  };

  return (
    <Dialog
      className="offer-dialog decline-dialog"
      sx={{ "& .MuiDialog-paper": { width: "80%", borderRadius: 5 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle className="title">Neka offert?</DialogTitle>
      <DialogContent className="content">
        Är du säker på att du vill neka offerten '
        <span className="highlighted-text">{offerName}</span>'? {"\n\n"}
        <br />
        <br />
        <TextField
          className="reason-field"
          fullWidth
          multiline
          label="Anledning (frivilligt)"
          onChange={(event) => setReason(event.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions className="action-buttons">
        <Button className="decline-button" autoFocus onClick={handleCancel}>
          Avbryt
        </Button>
        <Button className="confirm-button" onClick={handleDecline}>
          Neka offert
        </Button>
      </DialogActions>
    </Dialog>
  );
}
