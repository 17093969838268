import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import { OfferPage } from "./components/OfferPage";

function Empty() {
  return <div></div>;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#f6c613",
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Empty />} />
          <Route path="/o/:tenantId/:projectId/:offerId" element={<OfferPage />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
